



































































































































































































































































.labelGather{
  display: flex;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow-x: scroll;
  .gather{
    position: relative;
    flex: 1;
    max-height: 100%;
    min-width: 212px;
    margin: 20px 10px 10px 0;
    background-color: #f1f1f1;
    .gatherInner{
      min-height: 100%;
      max-height: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 0; /*高宽分别对应横竖滚动条的尺寸*/
        height: 0;
      }
    }
    p{
      font-size: 14px;
      line-height: 32px;
      padding: 0 52px 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      /deep/.el-button--text{
        font-size: 16px;
        display: none;
        width: 26px;
        position: absolute;
        top: 0;
        border: 0;
        &:nth-child(1){
          color: #e6a23c;
          right: 26px;
        }
        &:nth-child(2){
          color: #f00;
          right: 0;
        }
      }

      &:hover{
        /deep/.el-button--text{
          display: block;
        }
      }
    }
    &::before{
      content: '';
      position: absolute;
      width: 100%;
      top: -20px;
      left: 0;
      border-top-width: 10px;
      border-top-style: solid;
    }
    &:nth-child(1){
      color: #02A7F0;
      &::before{
        border-top-color: #02A7F0;
      }
    }
    &:nth-child(2){
      color: #EC808D;
      &::before{
        border-top-color: #EC808D;
      }
    }
    &:nth-child(3){
      color: #F59A23;
      &::before{
        border-top-color: #F59A23;
      }
    }
    &:nth-child(4){
      color: #FACD91;
      &::before{
        border-top-color: #FACD91;
      }
    }
    &:nth-child(5){
      color: #67C671;
      &::before{
        border-top-color: #67C671;
      }
    }
    &:nth-child(6){
      color: #C280FF;
      &::before{
        border-top-color: #C280FF;
      }
    }
    &:nth-child(7){
      color: #666;
      &::before{
        border-top-color: #D7D7D7;
      }
    }
  }
}
/deep/.el-form-item--small.el-form-item{
  position: relative;
}
.labelColor{
  position: absolute;
  top: 11px;
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.dialog-footer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.colorPoint{
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  margin-right: 12px;
  cursor: pointer;
  &::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 12px;
    border: 2px solid #dfdfdf;
  }
}
.colorPoint-active{
  &::before{
    border: 2px solid #409EFF;
  }
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__headerbtn{
  top: 20px;
}
/deep/.el-dialog__header .el-dialog__headerbtn .el-dialog__close{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  border-color: #333;
}
